/* You can add global styles to this file, and also import other style files */
@import "./variables.scss";
@import "./assets/animated.scss";
@import "~bootstrap/dist/css/bootstrap.css";
//@import '~@angular/material/theming';

/********FONTS************/
@font-face {
    font-family: "Epilogue";
    src: url("assets/fonts/Epilogue-VariableFont_wght.ttf");
}
@font-face {
    font-family: "Epilogue-Italic";
    src: url("assets/fonts/Epilogue-Italic-VariableFont_wght.ttf");
}
@font-face {
    font-family: "Fraunces";
    src: url("assets/fonts/Fraunces-VariableFont_SOFT\,WONK\,opsz\,wght.ttf");
}
@font-face {
    font-family: "Fraunces-Italic";
    src: url("assets/fonts/Fraunces-Italic-VariableFont_SOFT\,WONK\,opsz\,wght.ttf");
}
@font-face {
    font-family: "DMSans";
    src: url("assets/fonts/DMSans-Regular.ttf");
}
/****************************************/

html, body { height: 100%; font-family: 'Epilogue', sans-serif;}
::-webkit-scrollbar {
    width: 10px;
  }

  .mat-tab-group.mat-primary .mat-ink-bar{
    background-color: $primary-color !important; //#004163
  }

  .mat-raised-button.mat-primary{
    background-color: $primary-color !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary-color;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

$mat-myapp-primary: (
    50: #e4f6ee,
    100: #bce9d5,
    200: #90daba,
    300: #64cb9e,
    400: #42bf89,
    500: #21b474,
    600: #1dad6c,
    700: #18a461,
    800: #149c57,
    900: #0b8c44,
    A100: #bcffd6,
    A200: #89ffb7,
    A400: #56ff98,
    A700: #3cff88,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-myapp-accent: (
    50: #e4f6ee,
    100: #bce9d5,
    200: #90daba,
    300: #64cb9e,
    400: #42bf89,
    500: #21b474,
    600: #1dad6c,
    700: #18a461,
    800: #149c57,
    900: #0b8c44,
    A100: #bcffd6,
    A200: #89ffb7,
    A400: #56ff98,
    A700: #3cff88,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

//$primary: mat-palette($mat-myapp-primary);
//$accent: mat-palette($mat-myapp-accent);

//$theme: mat-light-theme($primary, $accent);

//@include angular-material-theme($theme);

.txt-main{
    color : #084464 !important;
}

.bg-main{
    background-color : #084464 !important;
}

.bg-danger{
  background-color : $danger-color!important;
}

.full{
    min-height: 100vh;
}

.full-scroll{
    height: 100vh;
    overflow: auto;
}

.full-width{
    width: 100%;
}

.fixed{
    position: fixed;
    z-index: 999;
}

.mat-drawer-inner-container{
    overflow: hidden!important;
}

.center-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursor{
    cursor: pointer;
}

.back-btn{
    width: 100px;
}

.info-block{
    border-left: 6px solid $primary-color;
    border-radius: 6px;
}

button:disabled {
    cursor: not-allowed!important;
}

a{
    color: gray!important;
}

.full-video{
    width: 100%!important;
    height: 100%!important;
}

.call-button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: $primary-color!important;
}

.custom-btn{
    border: none;
    background: no-repeat;
    border-bottom: 1px solid $gris2;
    color: $gris2;
    font-weight: bold;
    &:hover{
        background: $gris;
    }
}

.icon-doctor{
    width: 35px;
    height: 35px;
    border-radius: 6px;
    background-color: $primary-color2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none!important;
    cursor: pointer;
}

.icon-info{
    width: 35px;
    height: 35px;
    background-color: #9EADE5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: scale(0.5);
}

.dot{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $primary-color2;
}

.raleway{
    font-family: 'Raleway', sans-serif;
}

.epilogue{
    font-family:'Epilogue', 'Raleway', sans-serif;
}

.dmsans{
    font-family: "DMSans", "Helvetica Neue", sans-serif !important;
}

.semi-bolt{
    font-weight: 600;
}

.available{
    background-color: #90D5AC;
    color: white;
    border-radius: 50%;
}

.mat-dialog-container{
    padding: 0!important;
}

.img-responsive{
    max-width: 100%;
    height: auto;
}

.text-dark{
    color:#3a4156 !important;
}

.back-white-transparent{
    background-color: rgba(255,255,255,0.8);
}

.border-rounded-5{
    border-radius: 5px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
    background-color: $primary-color2!important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
    background-color: $primary-color2!important;
}

::ng-deep .mat-calendar-body-cell[disabled]{
    pointer-events: none;
}
::ng-deep .mat-calendar-body-cell[disabled] .mat-calendar-body-cell-content {
    background-color: rgba(128, 128, 128, 0.637);
}